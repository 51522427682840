<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" class="pa-0">
          <BaseButton
            data-testid="drive-import-button"
            class="mb-3 mr-3 d-inline-flex"
            @click="localDriveImport"
          >
            <Icon>{{ svgDrive }}</Icon>
            Import from local drive
          </BaseButton>
          <BaseButton
            data-testid="instagram-import-button"
            class="mb-3 mr-3 d-inline-flex"
            :loading="instaTokenLoading"
            @click="instagramIntegration"
          >
            <Icon>{{ svgInstagram }}</Icon>
            {{
              instaTokenValid
                ? `${$t("instagram.browse")}`
                : `${$t("instagram.connect")}`
            }}
          </BaseButton>
          <BaseButton
            data-testid="unsplash-import-button"
            class="mb-3 d-inline-flex"
            @click="modals.unsplash = true"
          >
            <Icon>{{ svgCamera }}</Icon>
            Browse unsplash
          </BaseButton>
        </v-col>
      </v-row>
    </v-container>
    <InstagramImagesModal
      :active="modals.instagram"
      :token="accessToken"
      class="fs-block"
      @closeModal="close"
      @picturesImport="instagramImport"
    ></InstagramImagesModal>
    <UnsplashImagesModal
      :active="modals.unsplash"
      class="fs-block"
      @closeModal="close"
      @picturesImport="unsplashImport"
    >
    </UnsplashImagesModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mdiCamera, mdiInstagram, mdiTapeDrive } from "@mdi/js";
import { analyticsLogEvent } from "@/helpers/analyticsHelpers";
import * as gaEventNames from "@/options/analyticsOptions";

export default {
  name: "ImagesAddModal",
  components: {
    UnsplashImagesModal: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Images/ImagesUnsplashModal.vue"
      ),
    InstagramImagesModal: () =>
      import(
        /* webpackPrefetch: true */ "@/components/Forms/Images/ImagesInstagramModal.vue"
      )
  },
  props: {
    instagramModalVisible: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    isAct: false,
    modals: {
      instagram: false,
      unsplash: false
    },
    instaTokenValid: false,
    instaTokenLoading: true,
    pictures: [],
    picturesToImport: [],
    paging: {},
    loaded: false,
    svgInstagram: mdiInstagram,
    svgDrive: mdiTapeDrive,
    svgCamera: mdiCamera
  }),
  computed: {
    ...mapState("boards", ["currentBoard"]),
    ...mapGetters("users", ["instagramAccount"]),
    accessToken() {
      return this.instagramAccount?.accessToken || "";
    }
  },
  watch: {
    instagramModalVisible: function(newVal) {
      if (newVal) {
        this.modals.instagram = true;
      }
    },
    "modals.instagram": function(newVal) {
      if (!newVal) {
        this.toggleModal("instagram");
      }
    }
  },
  mounted() {
    this.checkInstagramToken();
    if (this.instagramModalVisible) {
      this.modals.instagram = true;
    }
  },
  methods: {
    ...mapActions("modals", ["toggleModal"]),
    close(modalName) {
      this.modals[modalName] = false;
    },
    instagramIntegration() {
      this.checkInstagramToken();
      if (this.instaTokenValid) {
        this.modals.instagram = true;
      } else {
        const redirectUri = `${window.location.protocol}//${window.location.host}/${process.env.VUE_APP_INSTAGRAM_REDIRECT_ROUTE}`;
        window.location.href = `${process.env.VUE_APP_INSTAGRAM_API_URL}/oauth/authorize?client_id=${process.env.VUE_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${redirectUri}&state=${this.currentBoard.id}&scope=user_profile,user_media&response_type=code`;
      }
    },
    localDriveImport() {
      this.$emit("localDriveButtonClick");
    },
    unsplashImport(images) {
      analyticsLogEvent(gaEventNames.board_images_added, {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user,
        board_images_count: images.length,
        board_images_upload_source: "unsplash"
      });
      this.$emit("picturesImport", images);
      this.unsplashModal = false;
      this.$emit("close");
    },
    instagramImport(images) {
      analyticsLogEvent(gaEventNames.board_images_added, {
        board_id: this.currentBoard.id,
        board_user_id: this.currentBoard.user,
        board_images_count: images.length,
        board_images_upload_source: "instagram_account"
      });
      this.$emit("picturesImport", images);
      this.modals.instagram = false;
      this.$emit("close");
    },
    checkInstagramToken() {
      this.instaTokenLoading = true;
      if (this.accessToken) {
        return fetch(
          `${process.env.VUE_APP_INSTAGRAM_GRAPH_API_URL}/me?fields=id,username&access_token=${this.accessToken}`
        ).then(r => {
          this.instaTokenValid = r.status === 200;
          this.instaTokenLoading = false;
        });
      } else {
        this.instaTokenValid = false;
        this.instaTokenLoading = false;
      }
    }
  }
};
</script>
